<div id="container" class="container">
  <div class="logo-container">
    <img class="logo" src="../../assets/logo-text.png" alt="deathbox-logo" />
  </div>
  <!-- <h3 gaEvent="button_click" gaAction="info" class="info" (click)="infoClick()">
    How to play
  </h3> -->
  <div class="content">
    <div *ngIf="this.createClicked" id="create">
      <form class="form" *ngIf="this.createClicked" [formGroup]="createGameForm" id="create" method="POST">
        <div class="input-container">
          <label for="name">Who goes there?</label>
          <input id="name" formControlName="name" required autocomplete="off" placeholder="Name" />
        </div>
        <button gaEvent gaAction="create_game" (click)="createGame(createGameForm.value)" type="submit">
          Create Game
        </button>
      </form>
    </div>
    <div *ngIf="this.joinClicked" id="join">
      <form class="form" [formGroup]="joinGameForm" id="join" method="POST">
        <div class="input-container">
          <label for="name">Who goes there?</label>
          <input id="name" formControlName="name" required autocomplete="off" placeholder="Name" />
        </div>
        <div class="input-container" style="margin-top: 10px;">
          <label for="name">What's the password?</label>
          <input id="gameId" formControlName="id" required autocomplete="off" placeholder="Game ID" />
        </div>
        <button style="background-color: #6a985c;" gaEvent gaAction="join_game" (click)="joinGame(joinGameForm.value)" type="submit">
          Join Game
        </button>
      </form>
    </div>
    <div *ngIf="!this.joinClicked && !this.createClicked" class="button-container">
      <button gaEvent gaAction="create_game" (click)="createTrigger()" type="submit">
        Launch Game
      </button>
      <button style="background-color: #6a985c;" gaEvent gaAction="join_trigger" (click)="joinTrigger()">
        Join Game
      </button>
    </div>
  </div>
</div>