<h1>How to play Death Box</h1>
<ul>
    <li>Create a game</li>
    <li>Invite your friends</li>
    <li>Share your screen</li>

    <h2>Current Player</h2>
    <li>Choose any card</li>
    <li>Decide if the next card from the deck will be higher or lower</li>
    <li>Get 3 tries correct to move on to the next player</li>
    <li>If you guess wrong, your friends will decide your fate!</li>
</ul>